<script setup lang="ts">
// 1. Open side panel: 'pending' | 'ineligible'
// 	1.1   EvaluationPending
// 	1.2   Evaluating
// 	1.3   NotEligible
// 	1.4   NotEligibleBasic
// 2. Does nothing: 'excluded', 'future'
// 	2.1   New
// 	2.2   NotInvited
// 3. Go to campaign: 'eligible'
// 	3.1   EligibleBasic
// 	3.2   Eligible

import type { CampaignListItem } from "~/common/Types";
import { useRouter } from "vue-router";
import { cva } from "class-variance-authority";
import { CreatorappCampaignWeekUserEligibilityState as EligibilityState } from "evangelist-db-schema/api";

interface Props {
  campaign: CampaignListItem;
  inModal?: boolean;
  landingPageMode?: boolean;
  customTotalBudgetText?: string;
  isFirst?: boolean;
  isDark?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  inModal: false,
  landingPageMode: false,
  isFirst: false,
  isDark: false,
  customTotalBudgetText: "Total Budget",
});

const emit = defineEmits(["openSidebar"]);
const router = useRouter();

const inModal = computed(() => {
  return props.inModal;
});

const firstRow = cva(
  ["border-[#CFCBFF] bg-[#CFCBFF] outline-none border-none"],
  {
    variants: {
      isClickable: {
        true: [
          "cursor-pointer bg-gradient-to-b hover:from-white hover:to-white hover:border-white",
        ],
        false: null,
      },
    },
  },
);

const row = cva([], {
  variants: {
    inModal: {
      true: "bg-gradient-to-b from-gray-100 to-gray-200 border border-gray-300 campaign-row",
      false:
        "border border-white bg-gradient-to-b from-white/50 to-transparent",
    },
    isClickable: {
      true: ["cursor-pointer"],
      false: "",
    },
    isDark: {
      true: "border-[#B9B9B9CC] text-white to-transparent !from-[#B0B0B0]/20",
      false: "",
    },
  },
  compoundVariants: [
    {
      inModal: true,
      isClickable: true,
      class: "hover:from-white hover:to-white hover:border-white",
    },
    {
      inModal: false,
      isClickable: true,
      class: "hover:bg-white hover:shadow-blur",
    },
  ],
});

const isClickable = computed(
  () => props.campaign.eligibilityState !== EligibilityState.Excluded,
);

function resolveClick() {
  if (props.campaign.eligibilityState === EligibilityState.Eligible) {
    router.push(`/campaign/${props.campaign.campaignWeekId}`);
  }

  if (
    [EligibilityState.Pending, EligibilityState.Ineligible].includes(
      props.campaign.eligibilityState,
    )
  ) {
    globalEmit("campaignForSidebarClicked", {
      campaignWeekIdToOpen: props.campaign.campaignWeekId,
      seeCriteria: false,
    });
  }

  if (props.campaign.eligibilityState === EligibilityState.Excluded) {
    trackEvent("not_eligible_campaign_clicked", {
      campaignId: props.campaign.campaignWeekId,
    });
  }
}
</script>

<template>
  <NuxtLink
    v-if="props.campaign.eligibilityState === EligibilityState.Eligible"
    :to="`/campaign/${props.campaign.campaignWeekId}`"
  >
    <CampaignRowContent
      :class="[
        isFirst
          ? firstRow({ isClickable })
          : row({ isClickable, inModal, isDark }),
      ]"
      v-bind="props"
    />
  </NuxtLink>

  <div v-else @click="resolveClick">
    <CampaignRowContent
      :class="[
        isFirst
          ? firstRow({ isClickable })
          : row({ isClickable, inModal, isDark }),
      ]"
      v-bind="props"
    />
  </div>
</template>
